import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import PropTypes from 'prop-types';
import React from 'react';

import Framework from '../components/framework';
import Seo from '../components/framework/seo';
import withLocation from '../middleware/withLocation';

const TermsOfPurchase = ({ location }) => {
	const { t } = useTranslation('terms_of_purchase');

	return (
		<Framework location={location}>
			<Seo
				description={t('meta_description')}
				tags={t('meta_keywords')}
				title={t('title')}
			/>
			<div className="container mt-3 pt-lg-5">
				<h1 className="h3">{t('t1')}</h1>
				<h2 className="h4">{t('t2')}</h2>
				<p className="mb-3">{t('t3')}</p>
				<h2 className="h4">{t('t4')}</h2>
				<p className="mb-3">{t('t5')}</p>

				<h2 className="h4">{t('t6')}</h2>
				<p className="mb-3">{t('t7')}</p>
				<h2 className="h4">{t('t8')}</h2>
				<p className="mb-3">{t('t9')}</p>
				<h2 className="h4">{t('t10')}</h2>
				<p className="mb-3">{t('t11')}</p>
				<h2 className="h4">{t('t12')}</h2>
				<p className="mb-3">{t('t13')}</p>
				<h2 className="h4">{t('t14')}</h2>
				<p className="mb-3">{t('t15')}</p>
				<h2 className="h4">{t('t16')}</h2>
				<p className="mb-3">{t('t17')}</p>
				<h2 className="h4">{t('t18')}</h2>
				<p className="mb-3">{t('t19')}</p>
				<h2 className="h4">{t('t20')}</h2>
				<p className="mb-3">{t('t21')}</p>
				<p className="mb-3">{t('t22')}</p>
				<h2 className="h4">{t('t23')}</h2>
				<p className="mb-3">{t('t24')}</p>

				<h2 className="h4">{t('t25')}</h2>
				<p className="mb-3">{t('t26')}</p>

				<h2 className="h4">{t('t27')}</h2>
				<p className="mb-3">{t('t28')}</p>

				<p className="mb-3">{t('t29')}</p>
			</div>
		</Framework>
	);
};

TermsOfPurchase.propTypes = {
	location: PropTypes.object.isRequired,
};

export default withLocation(TermsOfPurchase);

export const query = graphql`
	query {
		locales: allTranslation(filter: { id: { ne: "dummy" } }) {
			...translationFields
		}
	}
`;
